:root {
  --bicycle--light-black: #212020;
  --swatch--cream: #fffff9;
}

.wf-active {
  animation-delay: 0s;
}

/* @keyframes fouc {
  0% {opacity: 0;}
  100% {opacity: 1;}
} */

html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-family: "GS", sans-serif;
  height: 100%;
  /* animation-duration: 0.2s;
  animation-delay: 2s;
  animation-name: fouc;
  animation-fill-mode: forwards; */
}

::-webkit-scrollbar {
	width: 0px;
}
::-webkit-scrollbar-thumb {
    display: none;
}

@font-face {
  font-family: "GS";
  src: url("/fonts/GS.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

body {
  min-height: 100%;
  color: #000101;
  margin: 0;
  font-family: "GS", sans-serif;
  font-size: 14px;
  background-color: #fffff9;
}

figure {
  margin: 0 0 10px;
}

button, [type="button"], [type="reset"] {
  cursor: pointer;
  -webkit-appearance: button;
  border: 0;
}

.form {
  margin: 0 0 15px;
}

.form-done {
  text-align: center;
  background-color: #fffff9;
  padding: 20px;
  display: none;
}

.form-fail {
  background-color: #fffff9;
  margin-top: 10px;
  padding: 10px;
  display: none;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
  display: block;
}

.char {
  opacity: 0;
  transform: translateY(111%);
  transition: transform 0.5s;
  }
  
.yep {
  overflow: hidden;
  padding-bottom: 0.203em;
  padding-right: 0.034em;
}

/* .sub .line {
  opacity: 0;
  transform: translateY(100%);
  transition: transform 0.5s;
} */

/* for floppy text char animation */
/* .head, .head2, .head3, .head4, .head5, .head6, .head7 {
  position: relative;
  z-index: 10;
  transition: opacity 0.5s;
  will-change: transform;
}

.sub, .sub2, .sub3, .sub4, .sub5 {
  opacity: 0;
  transition: opacity 0.5;
  position: relative;
  will-change: transform;
} */

/*---------------------- */

/* for all line animated text */
/* .text {
  opacity: 0;
  transition: opacity 0.5;
  position: relative;
  will-change: transform;
} */

/* .line {
  overflow: hidden;
} */


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  } 
}

.hln_body {
  background-color: #FFFFF9;
  font-size: 1vw;

  & h1 {
    opacity: 0;
    font-family: "GS", sans-serif;
    font-weight: 400;
    font-size: 7em;
    letter-spacing: -0.04em;
    margin: 0;
    will-change: transform;
    line-height: .75em;
    margin-bottom: 0.3em;
    overflow: hidden;
    text-align: center;
    animation: fadeIn;
    animation-duration: 0.95s;
    animation-fill-mode: forwards;
  
  }

  & h2 {
    font-family: "GS", sans-serif;
    font-weight: 400;
    font-size: 1.75em;
    letter-spacing: -0.04em;
    margin: 0;
    will-change: transform;
    overflow: hidden;
    text-align: center;
    /* display: inline-block; */
  }

  & h3 {
    font-family: "GS", sans-serif;
    font-weight: 400;
    letter-spacing: -0.04em;
    line-height: 0.9em;
    margin-block-start: 0.7em;
    margin-block-end: 0em;
    width: auto;
    overflow-wrap: normal;
    font-size: 4em;
    display: block;
    text-align: center;
    will-change: transform;
  }

  & h4 {
    font-family: "GS", sans-serif;
    font-weight: 400;
    letter-spacing: -0.04em;
    font-size: 9em;
  }

  & h5 {
    font-family: "GS", sans-serif;
    font-weight: 400;
    letter-spacing: -0.04em;
  }

  & h6 {
    font-family: "GS", sans-serif;
    font-weight: 400;
    letter-spacing: -0.04em;
    font-size: 7.5em;
  }
}

.hln-nav {
  margin-left: 1.5em;
  position: absolute;
  top: 1.5em;
  left: 0;
}

.hln__logo {
  width: 6.5em;
  max-width: none;
  overflow: hidden;
  opacity: 0;
  will-change: transform;
 }

#hero {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100vh;
}

.hero-text {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;
  will-change: transform;
}

.hero-image-contain {
  width: 50%;
  height: 100%; 
  overflow: hidden;
  position: relative;
  will-change: transform;
  background-color: #EACD80;
}

.hero__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 100%;
  opacity: 1;
  will-change: transform;
  animation: blur 1.5s forwards;

}

@keyframes blur {
  from {
  filter: blur(100px);
  }
  to {
  filter: blur(0);
  }
}

.hln-e2e {
  width: 100%;
  clear: none;
  background-color: rgba(0, 0, 0, 0);
  padding-left: 0;
  display: flex;
  position: static;

  &.first {
    height: 50em;
    background-color: #826AED;
  }

  &.middle {
    padding-right: 0;
    background-color: #3DDBE3;
    height: 50em;
  }

  &.last {
    height: 50em;
    background-color: #00AF54;
  }

  &.tiny {
    height: 25em;
    /* background-color: #F2E29F; */
    background-color: #e7e6e1;
  }
}

.hln-e2e-textside {
  width: 50%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.hln-e2e-textside__heading.mobile {
  display: none;
}

.hln-e2e-image-side {
  width: 50%;
  position: relative;
  overflow: hidden;
  height: 100%;
}

.hln-e2e-image-side img {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: relative;
}

.hln-e2e-textside__pg {
  line-height:  1.25em;
  font-family: "GS", sans-serif;
  letter-spacing: 0em;
  font-size: 1.5em;
  text-align: center;
  margin-block-end: 3em;
}

.hln-e2e-image-side__bg {
  width: 100%;
  height: 100%;
  background-color: #FFFFF9;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.hln-e2e-tiny-1 {
  width: 25%;
  overflow: hidden;
}

.hln-e2e-tiny-1 img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.hln-e2e-tiny-2 {
  width: 25%;
  text-align: center;
  font-size: 2.5em;
  line-height: 1.2em;
  align-items: center;
  display: flex;
  justify-content: center;
}

.hln-e2e-tiny-3 {
  width: 50%;
  overflow: hidden;
}

.hln-e2e-tiny-3 img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.hln-why-contain {
  height: auto;
  background-color: #000101;
  border-radius: 8em 8em 0 0;
  justify-content: center;
  padding: 11.5em 14.9em;
  display: flex;
  position: relative;
}

.hln-why-content {
  width: 100%;
}

.hln-why__heading {
  margin-block-start: 0em;
  margin-bottom: 0.75em;
  color: #FFFFF9;
}

.hln-why-box-contain {
  height: 60.75em;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.hln-why-box-top-contain {
  height: auto;
  align-items: flex-start;
  margin-bottom: 0;
  display: flex;
  overflow: visible;
}

.hln-why-box-top-left {
  width: 100%;
  height: 29.45em;
  background-color: #FFFFF9;
  border-radius: 2.75em;
  flex-direction: column;
  margin-right: .93em;
  padding: 3.325em 0 0;
  display: flex;
  overflow: hidden;
}

.hln-why-box-top-right {
  width: 100%;
  height: 29.45em;
  background-color: #fffff9;
  border-radius: 2.75em;
  margin-left: .93em;
  padding: 3.325em 0 0;
  overflow: hidden;
}

.hln-why-box-bottom {
  height: 29.45em;
  background-color: #fffff9;
  border-radius: 2.75em;
  flex-direction: column;
  padding: 3.325em 2.75em 0;
  display: flex;
}

.hln-why-box__heading {
  letter-spacing: -.035em;
  font-size: 3.3em;
  line-height: .9em;
  margin: 0;
  color: #000101;
  padding-left: 0.85em;
  font-family: "GS", sans-serif;
  font-weight: 400;
}

.hln-why-box__heading.is--bottom {
  padding-left: 0;
}

.hln-hero1 {
  height: 91.5vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  display: none;
}

.hln-hero1-text__heading {
  display: inline-block;
  margin-bottom: 0;
  color: #000101;
  letter-spacing: -.06em;
  margin-top: -.031em;
  margin-right: 0;
  padding-top: .06em;
  padding-bottom: 0;
  font-family: "GS", sans-serif;
  font-size: 8em;
  font-weight: 400;
  line-height: .92em;

  &.top {
    letter-spacing: -0.08em;
    margin-top: -.01em;
    padding-top: .05em;
    padding-bottom: 0;
    margin-bottom: 0;
    display: inline-block;
  }
}

.hln-page-wrapper {
  overflow: hidden;
}

.scroll-target {
  display: none;
}

.hln-top-scroll-trigger {
  height: 100vh;
  pointer-events: none;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: 0%;
  right: 0%;
}

.hln_overlap {
  z-index: 2;
  height: auto;
  background-color: #000101;
  border-radius: 8em 8em 0 0;
  display: block;
  position: relative;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.hln-lottie-e2e {
  width: 100%;
  margin-top: 0;
  padding-top: .75em;
  padding-left: .6em;
  position: absolute;
  overflow: visible;
}

.hln-e2e__feedvideo {
  z-index: 4;
  width: 20em;
  height: 41.9em;
  object-fit: fill;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
}

.hln-e2e__postvideo {
  z-index: 4;
  border-radius: 0;
  width: 20em;
  height: 41.9em;
  object-fit: fill;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  overflow: hidden;
}

.hln-why__lottie-eyes {
  width: auto;
  margin-top: 1.5em;
  margin-bottom: 0;
  padding-top: 0;
}

.whatsay {
  background-color: #3DDBE3;
  height: 50em;
  display: flex;
}

.hln-whatsay-image-contain {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
}

.hln-whatsay-image-contain img {
  width: 100%;
  height: auto;
  object-fit: cover;
  max-width: 100%;
  position: relative;
}

.hln-whatsay-testimonial {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.hln-whatsay__heading.mobile {
  display: none;
}

.hln-e2e-textside__pg.mobile {
  display: none;
}

.hln-whatsay__heading {
  padding-bottom: 1.1em;
}

.hln-whatsay-testimonial-contain {
  flex-direction: column;
  height: auto;
  grid-row-gap: 2em;
  margin-top: 0;
  display: flex;
  padding: 0 7em 0 7em;
  justify-content: center;
}

.hln-whatsay__testimonial {
  flex-direction: row;
  justify-content: center;
  display: flex;
  /* opacity: 0;
  transition: opacity 0.5s; */

  &.middle, &.top {
    border-radius: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  &.last {
    border-radius: 0;
    margin-bottom: 0;
    padding-bottom: 1rem;
  }
}

.hln-whatsay-testimonial-image {
  width: 6.5em;
  height: 6.5em;
  overflow: hidden;
}

.hln-whatsay-testimonial-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hln-testimonial__pg {
  color: #000101;
  font-size: 1.2em;
  line-height: 1.3em;
  font-family: "GS", sans-serif;
  padding: 0 0 0 1em;
  width: 24em;
}

.hln-testimonial-name__text {
  font-size: 1.2em;
  color: #000101;
  line-height:  0em;
  text-align: right;
  font-family: "GS", sans-serif;
}

.signup {
  width: 100%;
  height: 50em;
  background-color: #fffff9;
  font-family: "GS", sans-serif;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.hln-signup__heading {
  margin-top: 0;
  margin-bottom: 0;
}

.hln-signup-form {
  width: auto;
  height: auto;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.hln-signup-form-block {
  width: 24em;
  height: auto;
  margin-top: 11em;
  margin-bottom: 0;
  font-family: "GS", sans-serif;
  font-weight: 400;
}

.hln-signup-form-contain {
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  height: 10.5em;
}

#send {
  transition: all 0.4s;
  background-color: #000101;

  &:hover {
    background-color: #3DDBE3;
    color: #000101;
  }
}

.hln-signup__name-form {
  font-family: "GS", sans-serif;
  height: 3.2em;
  font-size: 1.4em; 
  border: .14em solid #000101;
  width: 100%;
  color: #000101;
  margin-bottom: 0;
  padding: 0 .8em;
  background-color: #FFFFF9;
}

.hln-signup__name-form:focus {
  border-color: #00AF54;
}

.hln-signup__name-form:focus-visible {
  border-color: #00AF54;
}

.hln-signup__email-form {
  font-family: "GS", sans-serif;
  height: 3.2em;
  font-size: 1.4em; 
  letter-spacing: 0em;
  border: .14em solid #000101;
  width: 100%;
  background-color: #fffff9;
  color: #000101;
  margin-bottom: 0;
  padding: 0 .8em;
  line-height: 1em;
  font-weight: 400;
}

.hln-signup__email-form:focus {
  border-color:#00AF54;
}

.hln-signup__send-button {
  font-family: "GS", sans-serif;
  font-size: 1.5em;
  width: 5em;
  height: 2.8em;
  background-color: #000101;
  color: #FFFFF9;
  border-radius: .25em;
  margin-top: 2em;
  padding: 0;
  font-weight: 400;
  line-height: 1em;
}

.hln-signup__send-button:hover {
  background-color: #00AF54;
}

.hln-signup-success__pg {
  margin-top: 2em;
  font-family: "GS", sans-serif;
  font-size: 1.1em;
  line-height: 1em;
  display: flex;
  justify-content: center;
}

.hln-signup-success {
  background-color: #fffff9;
}

.hln-signup-content {
  width: 50%;
  padding: 0 7em 0 7em;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hln-signup-image-contain {
  width: 50%;
  overflow: hidden;
  position: relative;
  display: flex;
}

.hln-signup-image-contain img {
  width: 100%;
  position: relative;
  object-fit: cover;
  object-position: center;
  height: auto;
  padding: 0;
  left: 0;
  bottom: 0;
}

.hln-reachout {
  height: 27em;
  background-color: #000101;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding-left: 7em;
}

.hln-reachout-content {
  align-items: center;
  display: flex;
}

.hln-reachout__heading {
  line-height: 0.9em;
  color: #fffff9;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 2.4em;
}

.hln-reachout-email-contain {
  width: auto;
  height: auto;
  background-color: #fffff9;
  border-radius: .25em;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.hln-reachout__email-text {
  font-family: "GS", sans-serif;
  font-size: 1.4em;
  letter-spacing: 0;
  color: #000101;
  margin-top: 0;
  padding-left: 1em;
  padding-right: .75em;
  margin-bottom: 0;
}

.hln-footer {
  height: 5em;
  background-color: #fffff9;
  align-items: center;
  display: flex;
}

.hln-copy-icon__front {
  transition: all 0.4s;
  border: 2px solid #000101;
  width: 16px;
  height: 16px;
  background-color: #3DDBE3;
  border-radius: .2em;
  position: absolute;
  top: 0%;
  bottom: auto;
  left: auto;
  right: 0%;
}

.hln-copy-icon {
  width: 21px;
  height: 21px;
  /* width: 1.6em;
  height: 1.6em; */
  border: 0.15em none #000101;
  align-items: flex-end;
  display: flex;
  position: relative;
  right: 1px;
  top: 1px;

  & svg {
    width: 100%;
    height: 100%;
  }
}

.hln-copy-icon__back {
  border: 2px solid #000101;
  width: 16px;
  height: 16px;
  /* border: 0.15em solid #000101;
  width: 1.2em;
  height: 1.2em;
  border-radius: .2em; */
}

.hln-reachout-icon-contain {
  width: auto;
  background-color: transparent;
  justify-content: flex-end;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;
}

.hln-copy-button {
  background-color: #3DDBE3;
  justify-content: center;
  min-height: 100%;
  height: 4em;
  width: 4em;
  cursor: pointer;
  border-top-right-radius: .25em;
  border-bottom-right-radius: .25em;
  align-items: center;
  margin-bottom: 0;
  display: flex;
  transition: all 0.4s;

  &:hover {
    background-color: #826AED;
    color: #000101;

    & .hln-copy-icon__front {
      background-color: #826AED
    }
  }
}

.hln-footer-contain {
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 1.5em;
  margin-right: 1.5em;
  display: flex;
}

.hln-footer-links-contain {
  margin-top: 1em;
  display: flex;
  align-items: center;
}

.hln-footer-social-contain {
  grid-column-gap: 2em;
  grid-row-gap: 2em;
  justify-content: space-between;
  display: flex;
}

.hln-footer-copyright {
  justify-content: center;
  align-items: center;
  display: flex;
  padding-left: 2em;
  padding-bottom: 0.6em;
}

.hln-footer-copyright__text {
  color: #000101;
  font-family: "GS", sans-serif;
  font-size: 1.4em;
  font-weight: 400;
  margin-bottom: 0;
}

.hln-footer-brand__svg {
  width: 14.75em;
  padding-top: 0.5em;
}

.hln__discord-icon {
  width: 1.75em;
}

.hln__github-icon {
  width: 1.75em;
}

.hln-why__lottie-target, .hln-why__lottie-files {
  height: auto;
}

.hln-reachout-heading-hide {
  overflow: hidden;
}

.otherbody {
  background-color: #000101;
}

.hln_beginning {
  position: relative;
}

.hln-footer-copyright__link {
  text-decoration: none;
}

.hln-footer-copyright__link:hover {
  text-decoration: underline;
}

.hln-footer-copyright__link.w--current {
  text-decoration: none;
}

.hln-footer-copyright__link.w--current:hover {
  text-decoration: underline;
}

@media screen and (min-width: 992px) and (max-width: 1023px) {
  .hln__logo {
    width: 8em;
  }

  .hln-e2e-textside__pg {
    font-size: 18px;
  }

  .hln_body {
    & h1 {
      font-size: 8.5em;
    }

    & h2 {
      font-size: 26px;
    }

    & h3 {
      font-size: 4.5em;
    }

    & h4 {
      font-family: "GS", sans-serif;
      font-weight: 400;
      letter-spacing: -0.04em;
      font-size: 9em;
    }

    & h5 {
      font-size: 3.75em;
    }

    & h6 {
      font-size: 8.5em;
    }

    & h7 {
      font-size: 1.3em;
      line-height: 1.2em;
    }
  }

  .hln-why-box-top-left {
    padding: 2.5em 0 0;
  }

  .hln-why-box-top-right {
    padding: 2.5em 0 0;
  }

  .hln-why-box-bottom {
    padding: 2.5em 2.5em 0;
  }

  .hln-why-box__heading {
    padding-left: 0.7em;
  }

  .hln-testimonial__pg {
    font-size: 14px;
    margin-block-start: 0em;
  }

  .hln-testimonial-name__text {
    font-size: 14px;
  }

  .hln-whatsay-testimonial-contain {
    padding: 0 0em 0 0em;
  } 

  .hln-whatsay__heading.desktop {
    padding-bottom: 0.7em;
  }

  .hln-signup__name-form {
    font-size: 16px;
  }

  .hln-signup__email-form {
    font-size: 16px;
  }

  .hln-signup-content {
    padding: 0 5em 0 5em;
  }

  .hln-signup__send-button {
    font-size: 16px;
  }

  .hln-signup-form-contain {
    height: 11.5em;
  }

  .hln-reachout__heading {
    margin-right: 1.5em;
  }


  .hln-reachout-email-contain {
    height: 4.3em;
  }

  .hln-reachout__email-text {
    font-size: 16px;
    padding-left: 0.8em;
  }

  .hln-copy-icon__front {
    width: 14px;
    height: 14px;
    border-radius: .1em;
  }

  .hln-copy-icon__back {
    width: 14px;
    height: 14px;
    border-radius: .1em;
  }

  .hln-copy-icon {
    width: 1.5em;
    height: 1.5em;
  }

  .hln-copy-button {
    width: 4.25em;
  }

  .hln-footer-copyright__text {
    font-size: 16px;
  }

  .hln-footer {
    height: 6em;
  }

  .hln__github-icon {
    width: 2.15em;
  }

  .hln__discord-icon {
    width: 2.15em;
  }

}

@media screen and (min-width: 741px) and (max-width: 991px) {
  .hln-nav {
    margin-left: 2em;
  }

  .hln__logo {
    width: 11em;
    height: 5em;
  }
  
  #hero {
    height: 100vh;
    flex-direction: row;
    background-color: #FFFFF9;
  }

  .hero-image-contain {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
  }

  .hero__image {
    width: 100%;
    /* object-position: 0px -87px; */
  }

  .hero-text {
    width: 100%;
    height: 100vh;
  }

  .hero-text__heading {
    font-size: 12em;
    margin-bottom: 0.25em;
    margin-top: 1.5em;
  }

  .hero-text__subheading {
    font-size: 3em;
  }

  .hln-testimonial__pg {
    font-size: 15px;
    margin-block-start: 0em;
  }

  .hln_body {
    & h1 {
      font-size: 8.5em;
    }

    & h2 {
      font-size: 2.5em;
    }

    & h3 {
      font-size: 4.8em;
    }

    & h4 {
      font-family: "GS", sans-serif;
      font-weight: 400;
      letter-spacing: -0.04em;
      font-size: 10.5em;
    }

    & h5 {
      font-size: 4em;
    }

    & h6 {
      font-size: 9.5em;
    }

    & h7 {
      font-size: 1.3em;
      line-height: 1.2em;
    }
  }

  .hln-e2e-textside__pg {
    font-size: 2.1em;
    margin-block-end: 2em;  
  }

  .hln-why-contain {
    /* padding: 13.5em 14.9em; */
    padding: 9.5em 11.9em;

    /* padding: 6.5rem 5.9rem; */
    /* height: 100%; */
  }

  .hln-why-box-contain {
    height: auto;
  }

  .hln-why-box__heading {
    padding-left: 0.5em;
  }

  .hln-why-box-top-left {
    padding: 2em 0 0;
    height: 31.8em;
    /* padding: 1.5rem 0 0;
    height: 19rem;
    border-radius: 1.5rem; */
  } 

  .hln-why-box-top-right {
    padding: 2em 0 0;
    height: 31.8em;
    /* padding: 1.5rem 0 0;
    height: 19rem;
    border-radius: 1.5rem; */
  }

  .hln-why-box-top-contain {
    margin-bottom: 1.8em;
  }

  .hln-why-box-bottom {
    padding: 2em 0 0;
    height: 31.8em;
    /* padding: 1.5rem 0 0;
    height: 19rem;
    border-radius: 1.5rem; */
  }

  .hln-why-box__heading.is--bottom {
    padding-left: 0.5em;
  }

  .hln-why__lottie-eyes {
    width: auto;
    margin-top: 2.5em;
    margin-bottom: 0;
    padding-top: 0;
    padding-left: 2.25em;
    padding-right: 2.25em;
  }

  .hln-whatsay__heading {
    padding-bottom: .5em;
  }

  .hln-whatsay-testimonial-contain {
    padding: 0;
  }

  .hln-testimonial__pg {
    padding: 0 0 0 0.7em;
  }

  .hln-testimonial__pg {
    font-size: 12px;
  }

  .hln-testimonial-name__text {
    font-size: 12px;
  }

  .hln-signup-content {
    padding: 0 5em 0 5em;
  }

  .hln-signup-form-block {
    margin-top: 6em;
  }

  .hln-signup-form-contain {
    height: 6rem;
  }

  .hln-signup__name-form {
    font-size: 13px;
  }

  .hln-signup__email-form {
    font-size: 13px;
  }

  .hln-reachout__email-text {
    font-size: 13px;
  }

  .hln-signup__send-button {
    font-size: 15px;
  }

  .hln-reachout__heading {
    margin-right: 1em;
  }

  .hln-reachout-email-contain {
    width: 14rem;
    height: 3rem;
    justify-content: space-between;
  }

  .hln-copy-button {
    height: 3rem;
    width: 3rem;
  }

  .hln-copy-icon {
    width: 1.35rem;
    height: 1.35rem;
    border: 0.15rem none #000101;
  }

  .hln-footer {
    height: 7em;
  }

  .hln-footer-copyright__text {
    font-size: 13px;
  }

  .hln__discord-icon {
    width: 2em;
  }

  .hln__github-icon {
    width: 2em;
  }
}

@media screen and (max-width: 497px) {
  .hln__logo {
    width: 22em;
    height: 9em;
  }
}

@media screen and (min-width: 498px) and (max-width: 740px) {
  .hln__logo {
    width: 15em;
    height: 6em;
  }

  .whatsay {
    flex-direction: column;
    height: initial;
  }
}

@media screen and (max-width: 740px) {
  .hln-nav {
    margin-left: 2em;
  }

  .hln_body h1 {
    font-size: 17.1em;
    margin-bottom: 0.2em;
  }

  .hln_body h2 {
    font-size: 5.5em;
  }

  .hln_body h3 {
    font-size: 10.1em;
  }

  .hln_body h7 {
    font-size: 1.3em;
    line-height: 1.2em;
  }
  
  #hero {
    height: 140vh;
    flex-direction: column;
    background-color: #FFFFF9;
    display: flex;
    align-items: center;
    padding-bottom: 2em;
  }

  .hero-image-contain {
    width: 96%;
    display: flex;
    justify-content: center;
    height: 80vh;
  }

  .hero__image {
    width: 100%;
    object-position: 30% top;
    height: auto;
  }

  .hero-text {
    width: 100%;
    height: 60vh;
    padding-top: 15em;
  }

  .hero-text__heading {
    font-size: 16em;
    margin-bottom: 0.25em;
    margin-top: 1.5em;
  }

  .hero-text__subheading {
    font-size: 5em;
  }

  #hln-e2e {
    overflow: hidden;
  }

  .hln-e2e-textside__heading.mobile {
    display: block;
    margin-bottom: .5em;
  }

  .hln-e2e-textside__pg {
    font-size: 4.75em;
    margin-block-start: 1.2em;
    margin-block-end: 2em;
  }

  .hln-e2e-textside__pg.mobile {
    display: block;
    font-size: 4.75em;
    margin-block-start: 1.2em;
    margin-block-end: 2em;
  }

  .hln-e2e-textside__heading.desktop {
    display: none;
  }

  .hln-e2e-textside__pg.desktop {
    display: none;
  }

  .hln-e2e-image-side {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
  }

  .hln-e2e-image-side__bg {
    background-color: transparent;
  }


  .hln-e2e-image-side img {
    width: 96%;
  }

  .hln-e2e__feedvideo {
    width: 65%;
    height: 100%;
  }

  .hln-e2e__postvideo {
    width: 65%;
    height: 100%;
  }

  .hln-e2e-image-side {
    /* margin-top: 4em; */
  }

  .hln-e2e-textside {
    width: 100%;
    height: 16%;
  }

  .hln-e2e-textside.middle {
    width: 100%;
    height: 0%;
  }

  .hln-e2e.first {
    flex-direction: column;
    height: 210em;
    justify-content: space-between;
  }

  .hln-e2e.middle {
    flex-direction: column;
    height: 210em;
    justify-content: space-between;
    background-color: #FFFFF9;
  }

  .hln-e2e.last {
    flex-direction: column;
    height: 210em;
    justify-content: space-between;
  }

  .hln-e2e.tiny {
    flex-direction: row;
    height: 100em;
    background-color: #fffff9;
  }

  .hln-e2e-tiny-1 {
    overflow: hidden;
    width: 50%;
    height: 50em;
  }

  .hln-e2e-tiny-2 {
    width: 50%;
    text-align: center;
    font-size: 5.5em;
    line-height: 1.2em;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 50%;
  }

  .hln-e2e-tiny-3 {
    width: 100%;
    overflow: hidden;
    position: absolute;
    bottom: 0;
  }

  .hln_overlap {
    border-top-left-radius: 10em;
    border-top-right-radius: 10em;
  }

  .hln-why-contain {
    border-top-left-radius: 10em;
    border-top-right-radius: 10em;
    padding: 18em 2.5em 12em;
  }

  .hln_body h4 {
    font-family: "GS", sans-serif;
    font-weight: 400;
    letter-spacing: -0.04em;
    font-size: 14em;
  }

  .hln-why__heading {
    margin-bottom: 0.7em;
  }

  .hln-why-box-contain {
    height: auto;
  }

  .hln-why-box-top-contain {
    flex-flow: column;
    margin-bottom: 0;
  }

  .hln-why-box-top-left {
    height: 80em;
    margin-bottom: 2.5em;
    margin-right: 0;
    padding-top: 0;
  }

  .hln-why-box-top-right {
    height: 80em;
    margin-bottom: 2.5em;
    margin-left: 0;
    padding-top: 0;
  }

  .hln-why-box-bottom {
    height: 80em;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .hln_body h5 {
    font-family: "GS", sans-serif;
    font-weight: 400;
    letter-spacing: -0.04em;
    font-size: 10em;
  }

  .hln-why-box__heading {
    padding-top: 0.4em;
    padding-left: 0.4em;
  }

  .hln-why-box__heading.is--bottom {
    padding-left: .4em;
  }

  .hln-whatsay__heading.mobile {
    display: block;
    padding-bottom: 0.7em;
  }

  .hln-whatsay__heading.desktop {
    display: none;
  }

  .hln-signup-content-contain {
    flex-direction: column-reverse;
  }

  .hln-lottie-e2e {
    width: 140%;
  }

  .hln-why__lottie-eyes {
    width: 197%;
    align-self: flex-start;
    margin-top: 4.9em;
    padding-left: 3.5em;
  }

  .hln-why__lottie-target {
    width: 100%;
  }

  .hln-why__lottie-files {
    width: 100%;
    display: block;
  }

  .name-contain {
    order: 1;
  }

  .hln-whatsay {
    height: auto;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    display: block;
    position: relative;
    margin: 0em;
    overflow: hidden;
  }

  .hln-whatsay__heading-contain {
    width: 100%;
    height: 85em;
  }

  .hln-whatsay-image-contain {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  .hln-whatsay-image-contain img {
    width: 96%;
    height: 100%;
    object-fit: cover;
    max-width: 96%;
}

  .hln-whatsay__heading-text-contain {
    width: 64em;
    height: 48em;
  }

  .hln-whatsay-content {
    width: auto;
    align-self: auto;
    align-items: center;
    margin: 0;
    display: block;
    overflow: hidden;
  }

  .hln-whatsay__heading {
    letter-spacing: -.08em;
    font-size: 13em;
    line-height: 1em;
  }

  .hln-whatsay-testimonial-image {
    width: 20em;
    height: 20em;
  }

  .hln-whatsay-testimonial {
    width: 100%;
    display: flex;
    flex-direction: unset;
    justify-content: center;
  }

  .hln-whatsay-text-contain {
    width: 79%;
  }

  .hln-whatsay__testimonial.middle {
    width: 100%;
    margin-bottom: 0em;
  }

  .hln-whatsay__testimonial.top {
    width: 100%;
    margin-bottom: 1em;
    margin-top: 11em;
    margin-bottom: 0em;
  }

  .hln-whatsay__testimonial.last {
    width: 100%;
    padding-bottom: 11em;
  }

  .hln-testimonial__pg {
    font-size: 18px;
    width: 100%;
    padding: 0 0 0 0.4em;
    margin: 0;
    margin-block-start: 0em;
  }

  .hln-whatsay-testimonial-contain {
    grid-row-gap: 0em;
    width: 96%;
    padding: 0%;
  }

  .hln-testimonial-name__text {
    margin-top: 1.5em;
    margin-bottom: 3.2em;
    font-size: 18px;
  }
  .hln-whatsay__testimonial.last .hln-testimonial-name__text {
    margin-bottom: 0;
  }

  .signup {
    height: auto;
    align-items: flex-start;
    flex-direction: column-reverse;
    display: flex;
    align-items: center;
    padding-top: 2em;
  }

  .hln-signup-image-contain {
    width: 96%;
    position: relative;
    /* height: 100em; */
  }

  .hln-signup-image-contain img {
    position: relative;
    width: 100%;
    object-fit: cover;
    object-position: top;
    height: auto;
    padding: 0;
    left: 0;
    bottom: 0;
  }

  .hln-signup-content {
    width: 96%;
    margin-top: 0em;
    margin-bottom: 0em;
    align-items: center;
    padding: 0;
  }

  .hln-signup-form {
    flex-flow: column;
    margin-bottom: 12em;
  }

  .hln-signup-form-block {
    margin-top: 10em;
    width: 100%;
  }

  .hln-signup-form-contain {
    flex-flow: column;
    align-self: center;
    height: auto;
  }

  .hln-signup__name-form {
    width: 100%;
    height: auto;
    border-width: .15em;
    border-radius: 0em;
    margin-bottom: 1.5em;
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 18px;
    line-height: 1.1px;
  }

  .hln-signup__email-form {
    width: 100%;
    height: auto;
    border-width: .15em;
    border-radius: 0em;
    padding-top: 1em;
    padding-bottom: 1em;
    font-size: 18px;
  }

  .hln-signup__send-button {
    width: 135px;
    height: 70.125px;
    border-radius: 0.25em;
    margin-top: 2em;
    font-size: 23px;
    letter-spacing: 0em;
  }

  .hln_body h6 {
    font-size: 13.1em;
  }

  .hln-copy-icon__front, .hln-copy-icon__back{
    border: 2px solid;
  }

  .hln-reachout {
    height: auto;
    flex-flow: column;
    align-content: flex-start;
    display: flex;
    padding-left: 0;
  }

  .hln-reachout-heading-hide {
    overflow: hidden;
    display: flex;
    justify-content: center;
  }

  .hln-reachout-content {
    grid-column-gap: 10em;
    grid-row-gap: 10em;
    flex-flow: column;
    margin: 12em;
    width: 96%;
  }

  .hln-reachout-heading-contain {
    width: 100%;
  }

  .hln-reachout__heading {
    margin-right: 0;
  }

  .hln-reachout-email-contain {
    width: 100%;
    height: 66px;
    border-radius: 1.5em;
    justify-content: space-between;
  }

  .hln-reachout__email-text {
    padding: 0 0 0 0.8em;
    font-size: 20px;
  }

  .hln-footer {
    height: auto;
  }

  .hln-copy-button {
    width: 20em;
    height: 66px;
    border-top-right-radius: 1.5em;
    border-bottom-right-radius: 1.5em;
    justify-content: center;
    align-items: center;
  }

  .hln-copy-icon {
    width: 2.2em;
    height: 2.2em;
  }

  .hln-copy-icon__front {
    width: 1.5em;
    height: 1.5em;
    border-radius: 0.25em;
  }

  .hln-copy-icon__back {
    width: 1.5em;
    height: 1.5em;
    border-radius: .25em;
  }

  .hln-reachout-icon-contain {
    justify-content: center;
    align-items: center;
    padding-left: .25em;
    padding-right: .25em;
  }

  .hln-reachout__copy-button {
    width: 100%;
    height: 17.4em;
    border-top-right-radius: 3em;
    border-bottom-right-radius: 3em;
    justify-content: center;
    align-items: center;
  }

  .hln-footer-contain {
    height: 100%;
    flex-flow: column;
    justify-content: flex-start;
    align-self: flex-start;
    margin: 8em 2em 1.5em 2em;
  }

  .hln-footer-brand {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-self: center;
    display: flex;
  }

  .hln-footer-links-contain {
    width: 100%;
    grid-column-gap: 0em;
    grid-row-gap: 0em;
    flex-flow: row;
    justify-content: space-between;
    align-self: flex-end;
    align-items: stretch;
    margin-top: 10em;
  }

  .hln-footer-social-contain {
    grid-column-gap: 5em;
    grid-row-gap: 7em;
    order: -1;
    justify-content: flex-end;
    align-self: flex-end;
  }

  .hln__github-icon {
    width: 25.6px;
  }

  .hln__discord-icon {
    width: 25.6px;
  }

  .hln-footer-copyright {
    align-self: flex-end;
  }

  .hln-footer-copyright__text {
    font-size: 18px;
  }

  .hln-footer-brand__svg {
    width: 58em;
    align-self: stretch;
  }
}